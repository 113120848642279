import { apiService } from '../services';
var VueScrollTo = require('vue-scrollto');
 
export const utilitarios = {
    scrollParaElemento,
    replaceAll,
    formataData,
    formataNome,
    formataValor,
    makeId
};

export function scrollParaElemento(nomeElemento){
  // faço com o vuescrollto e com o scrollinto view, porque um funciona no windows e outro no mac, sem explicação

  // windows ok
  VueScrollTo.scrollTo("#" + nomeElemento, 200, {
      container: '#painel_principal',
      easing: 'ease'
  });

  // mac ok
  document.getElementById(nomeElemento).scrollIntoView({behavior: 'smooth'});
}



export function replaceAll(texto, textoBuscar, textoReplacement){
    var resultado = texto.split(textoBuscar).join(textoReplacement);
    return resultado;
}



export function formataData(data){
  return data.slice(0,10).split('-').reverse().join('/')
}
export function formataValor(valor){
  const x  = valor.toString().split('.').join(',')
  return x
}
export function formataNome(nome) {
   var words = nome.toLowerCase().split(" ");
   for (var a = 0; a < words.length; a++) {
       var w = words[a];
       words[a] = w[0].toUpperCase() + w.slice(1);
   }
   return words.join(" ");
}

function makeId() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}