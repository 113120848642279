import Vue from "vue";
import VueRouter from "vue-router";

import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Login/AuthLayout.vue";
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
import Login from "@/pages/Login/Login.vue";

//Gestor de Bases
import MinhasBases from "@/pages/GestorBases/MinhasBases.vue";
import AreaTransferencia from "@/pages/GestorBases/AreaTransferencia.vue";
import ProjetosDetalhes from "@/pages/GestorBases/ProjetosDetalhes.vue";
import ListaHistoricoProjetos from "@/pages/GestorBases/ListaHistoricoProjetos.vue";

//Assistente Virtual
import PaginaAssistente from "@/pages/AssistenteSingu/PaginaAssistenteSingu.vue";


// páginas personalizadas
import ConsoleApi from "@/pages/Suporte/ConsoleApi.vue";
import Contato from "@/pages/Suporte/Contato.vue";
import Referencia from "@/pages/Suporte/Referencia.vue";

import NovaConsulta from "@/pages/Consultas/Nova.vue";
import ListaHistoricoConsultas from "@/pages/Consultas/ListaHistorico.vue";
import Historico from "@/pages/Consultas/Historico.vue";
// import Lote from "@/pages/Consultas/Lote.vue";

import NovaConsultaBacktest from "@/pages/Backtest/NovaBacktest.vue";
import ListaHistoricoBacktest from "@/pages/Backtest/ListaHistoricoBacktest.vue";
import LoteBacktest from "@/pages/Backtest/LoteBacktest.vue";

import NovaConsultaModelos from "@/pages/Modelos/NovaConsultaMod.vue";
import NovaConsultaSuperbox from "@/pages/Superbox/NovaSup.vue";

import MinhaConta from "@/pages/MinhaConta/Conta.vue";
import Preferencias from "@/pages/MinhaConta/Preferencias.vue";

//consulta
import selecaoInicial from "@/pages/Consultas/Nova/selecaoInicial.vue";
import selecaoProdutos from "@/pages/Consultas/Nova/selecaoProdutos.vue";
import preenchimentoCampos from "@/pages/Consultas/Nova/preenchimentoCampos.vue";
import telaResultados from "@/pages/Consultas/Nova/telaResultados.vue";
import consultaCiclicaProdutos from "@/pages/Consultas/Nova/consultaCiclicaProdutos.vue";
import consultaCiclicaCampos from "@/pages/Consultas/Nova/consultaCiclicaCampos.vue";

//superbox
import selecaoInicialSuperbox from "@/pages/Superbox/Nova/selecaoInicialSup.vue";
import selecaoProdutosSuperbox from "@/pages/Superbox/Nova/selecaoProdutosSup.vue";
import preenchimentoCamposSuperbox from "@/pages/Superbox/Nova/preenchimentoCamposSup.vue";
import telaResultadosSuperbox from "@/pages/Superbox/Nova/telaSuperBox.vue";

//backtest
import telaInicialBacktest from "@/pages/Backtest/Nova/telaInicialBacktest.vue";
import selecaoPessoa from "@/pages/Backtest/Nova/selecaoPessoa.vue";
import selecaoAbordagem from "@/pages/Backtest/Nova/selecaoAbordagem.vue";
import preenchimentoLotes from "@/pages/Backtest/Nova/preenchimentoLotes.vue";
import arquivoLotes from "@/pages/Backtest/Nova/arquivoLotes.vue";
import mapeamentoLotes from "@/pages/Backtest/Nova/mapeamentoLotes.vue";
import resumoLotes from "@/pages/Backtest/Nova/resumoLotes.vue";
import statusLote from "@/pages/Backtest/Nova/statusLote.vue";

//consulta modelos
import selecaoInicialModelos from "@/pages/Modelos/Nova/selecaoInicialModelos.vue";
import selecaoProdutosModelos from "@/pages/Modelos/Nova/selecaoProdutosModelos.vue";
import preenchimentoCamposModelos from "@/pages/Modelos/Nova/preenchimentoCamposModelos.vue";
import telaResultadosModelos from "@/pages/Modelos/Nova/telaResultadosModelos.vue";

//integracao_skynet
import IntegracoesSkynet from "@/pages/IntegracaoSkynet/IntegracoesSkynet.vue";
import telaInicialIntegracao from "@/pages/IntegracaoSkynet/Passos/telaInicialIntegracao.vue";
import variavelResposta from "@/pages/IntegracaoSkynet/Passos/variavelResposta.vue";
import variavelAmostra from "@/pages/IntegracaoSkynet/Passos/variavelAmostra.vue";
// import safra from "@/pages/IntegracaoSkynet/Passos/safra.vue";
import variavelChave from "@/pages/IntegracaoSkynet/Passos/variavelChave.vue";
import linguagemEAbordagemPreditiva from "@/pages/IntegracaoSkynet/Passos/linguagemEAbordagemPreditiva.vue";
import resumoIntegracoes from "@/pages/IntegracaoSkynet/Passos/resumoIntegracoes.vue";
import statusIntegracoes from "@/pages/IntegracaoSkynet/Passos/statusIntegracoes.vue";

// import BookAtributos from "@/pages/Books/BookAtributos"
import Books from "@/pages/Books/ListagemDeBooks";
import DocBoxModelos from "@/pages/Books/DocBoxModelos";
import DocBoxAtributos from "@/pages/Books/DocBoxAtributos";

import { sessionStore } from "@/stores/session";
import { mapStores } from "pinia";

import Atualizacoes from "@/pages/Atualizacoes/Atualizacoes";

Vue.use(VueRouter);

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
  ],
};

let outboxMinhaConta = {
  path: "/",
  component: DashboardLayout,
  name: "Menu do usuário",
  children: [
    {
      path: "/conta",
      name: "Minha Conta",
      component: MinhaConta,
    },
    {
      path: "/preferencias",
      name: "Preferências",
      component: Preferencias,
    },
  ],
};

let outboxConsultas = {
  path: "/consultas",
  component: DashboardLayout,
  name: "Consultas",
  children: [
    {
      path: "/consultas/box",
      name: "Consultas / Box",
      component: NovaConsulta,
      children: [
        {
          path: "0",
          component: selecaoInicial,
          name: "Consultas / Nova Consulta Box",
        },
        { path: "1", component: selecaoProdutos },
        { path: "2", component: preenchimentoCampos },
        { path: "3", component: telaResultados },
        { path: "4", component: consultaCiclicaProdutos },
        { path: "5", component: consultaCiclicaCampos },
      ],
    },
    {
      path: "/consultas/superbox",
      name: "Consultas / Superbox",
      component: NovaConsultaSuperbox,
      children: [
        {
          path: "0",
          component: selecaoInicialSuperbox,
          name: "Consultas / Nova Consulta Superbox",
        },
        { path: "1", component: selecaoProdutosSuperbox },
        { path: "2", component: preenchimentoCamposSuperbox },
        { path: "3", component: telaResultadosModelos },
      ],
    },
    {
      path: "/consultas/modelos",
      name: "Consultas / Modelos",
      component: NovaConsultaModelos,
      children: [
        {
          path: "0",
          component: selecaoInicialModelos,
          name: "Consultas / Nova Consulta Modelos",
        },
        { path: "1", component: selecaoProdutosModelos },
        { path: "2", component: preenchimentoCamposModelos },
        { path: "3", component: telaResultadosModelos },
      ],
    },
    {
      path: "/consultas/historico",
      name: "Consultas / Histórico de Consultas",
      component: ListaHistoricoConsultas,
    },
    {
      path: "/consultas/historico/:codigo",
      name: "Consultas / Consulta Histórico",
      component: Historico,
    },
  ],
};

let outboxBacktest = {
  path: "/backtest",
  component: DashboardLayout,
  name: "Backtest",
  children: [
    {
      path: "/backtest/nova",
      name: "Backtest / Nova",
      component: NovaConsultaBacktest,
      children: [
        {
          path: "0",
          component: telaInicialBacktest,
          name: "Backtest / Nova Submissão",
        },
        { path: "1", component: selecaoPessoa },
        { path: "2", component: selecaoAbordagem },
        { path: "3", component: preenchimentoLotes },
        { path: "4", component: arquivoLotes },
        { path: "5", component: mapeamentoLotes },
        { path: "6", component: resumoLotes },
        { path: "7", component: statusLote },
      ],
    },
    {
      path: "/backtest/historico_backtest",
      name: "Backtest / Histórico de Backtest",
      component: ListaHistoricoBacktest,
    },
    {
      path: "/backtest/lotesBacktest",
      name: "Backtest / Lotes Backtest",
      component: LoteBacktest,
    },
  ],
};

let outboxIntegracoesSkynet = {
  path: "/integracoes_skynet",
  component: DashboardLayout,
  name: "Integracoes Skynet Principal",
  children: [
    {
      path: "/integracoes_skynet/:id",
      name: "Integrações Skynet",
      component: IntegracoesSkynet,
      children: [
        {
          path: "0",
          component: telaInicialIntegracao,
          name: "Integracoes com Skynet",
        },
        { path: "1", component: variavelResposta },
        { path: "2", component: variavelAmostra },
        { path: "3", component: variavelChave },
        { path: "4", component: linguagemEAbordagemPreditiva },
        { path: "5", component: resumoIntegracoes },
        { path: "6", component: statusIntegracoes },
      ],
    },
  ],
};

let outboxSuporte = {
  path: "/suporte",
  component: DashboardLayout,
  name: "Suporte",
  children: [
    {
      path: "/suporte/console_api",
      name: "Suporte / Console da API",
      component: ConsoleApi,
    },
    {
      path: "/suporte/contato",
      name: "Suporte / Contato",
      component: Contato,
    },
    {
      path: "/suporte/referencia",
      name: "Suporte / Referência",
      component: Referencia,
    },
  ],
};

let documentacaoBooks = {
  path: "/doc",
  component: DashboardLayout,
  children: [
    {
      path: "/doc/books",
      name: "Documentação de Books",
      component: Books,
    },
    {
      path: "/doc/box_modelos",
      name: "Documentação Box de Modelos",
      component: DocBoxModelos,
    },
    {
      path: "/doc/box_atributos",
      name: "Documentação Box de Atributos",
      component: DocBoxAtributos,
    },
  ],
};
let pageLogs = {
  path: "/logs",
  component: DashboardLayout,
  children: [
    {
      path: "/",
      name: "Atualizações",
      component: Atualizacoes,
    },
  ],
};

let uploadMinhasBases = {
  path: "/gestor_bases",
  component: DashboardLayout,
  children: [
    {
      path: "projetos",
      name: "Gestor de Bases - Projetos",
      component: MinhasBases,
    },
    {
      path: "area_transferencia",
      name: "Gestor de Bases - Área de Transferência",
      component: AreaTransferencia,
    },
    {
      path: "historico",
      name: "Histórico de Projetos",
      component: ListaHistoricoProjetos,
    },
    {
      path: "projetos/:guid",
      name: "Detalhes do Projeto",
      component: ProjetosDetalhes,
    },
  ],
};

let assistenteSingu = {
  path: "/singu",
  component: DashboardLayout,
  children: [
    {
      path: "chat",
      name: "Assistente Virtual - Singú",
      component: PaginaAssistente,
    },
   
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/inicio",
    name: "Início0",
  },
  uploadMinhasBases,
  assistenteSingu,
  documentacaoBooks,
  pageLogs,
  authPages,
  outboxMinhaConta,
  outboxSuporte,
  outboxConsultas,
  outboxBacktest,
  outboxIntegracoesSkynet,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "inicio",
        name: "Início",
        components: { default: Dashboard },
      },
    ],
  },
];

export const router = new VueRouter({
  mode: "history",
  routes: routes,
  linkExactActiveClass: "nav-item active",
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  if (loggedIn && !publicPages) {
    if (!loggedIn.hasOwnProperty("token")) {
      return next({
        path: "/login",
        query: { returnUrl: to.path },
      });
    }
  }
  if (authRequired && !loggedIn) {
    return next({
      path: "/login",
      query: { returnUrl: to.path },
    });
  }
  next();
});
