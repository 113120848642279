import Vue from "vue";
import DashboardPlugin from "./material-dashboard";
// Plugins
import App from "./App.vue";

// router setup
import { router } from "./helpers";
import VueMask from "v-mask";
import * as VueAos from "vue-aos";
import VueGtag from "vue-gtag";
import "./styles/main.css";
import { createPinia, PiniaVuePlugin } from "pinia";

import "core-js/stable";
import "regenerator-runtime/runtime";

import axios from "axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from "vue-sweetalert2";
import VueClipboard from "vue-clipboard2";
import ProgressBar from "vuejs-progress-bar";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import * as VueGoogleMaps from 'vue2-google-maps'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.use(VueGtag, {
  config: { id: "G-82ZK1XEEVT" },
  enabled: true,
});

Vue.use(VueMask);
Vue.use(VueAos);
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueClipboard);
Vue.use(ProgressBar);

Vue.mixin({
  methods: {
    analytics(name, category, label, value = 1) {
      this.$gtag.event(name, {
        event_category: category,
        event_label: label,
        value: value,
      });
    },
  },
});

Vue.use(VueAxios, axios, { baseUrl: "https://lote.outbox.com.br/lotes" });
// Vue.use(axios, { baseUrl: 'https://lote.outbox.com.br/lotes',});

Vue.use(VueSweetalert2);

Vue.filter("formatTime", function (value) {
  if (value) {
    const parts = value.split(":");
    return +parts[0] + "h " + +parts[1] + "min";
  } else {
    return "unknown";
  }
});

Vue.filter("formatTimeWithDays", function (tempo) {
  if (tempo && tempo !== "00:00:00" && tempo !== "") {
    let dias = "00";
    let momento = tempo;

    if (tempo.indexOf(".") < tempo.indexOf(":")) {
      [dias, momento] = tempo.split(".");
    }

    const [horas, minutos] = momento.split(":");

    let diasTexto = dias === "1" ? "dia" : "dias";
    let horasTexto = horas === "01" ? "hora" : "horas";

    let resultado = "";

    if (dias !== "00") {
      resultado += `${dias} ${diasTexto} `;
    }
    if (horas !== "00") {
      resultado += `${horas} ${horasTexto} `;
    }
    resultado += `${minutos} min`;

    return resultado.trim();
  } else {
    return "00:00:00";
  }
});

Vue.use(VueGoogleMaps, {
  load: {
      key: 'AIzaSyDai3Rx0fB0sCuZGchADkXzXpXAhXjl3oA',
      async: true,
      libraries: 'geometry' 
  },
}),

/* eslint-disable no-new */
new Vue({
  el: "#app",
  pinia,
  render: (h) => h(App),
  router,
  data: {},
});
