export const mascaras = {
   cpf,
   cnpj,
   cep,

}

export function cpf(cpf){
    return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
}

export function cnpj(cnpj){
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
}

export function cep(cep){
    return cep.replace(/^(\d{5})(\d{3})/, "$1-$2")
}
